<template>
  <div>
    <div class="info-top">
      <h6>
        Show Payments
      </h6>
      <a class="custom-btn4" @click="getBack">
        <span>Back</span>
      </a>
    </div>
    <table class="table table-responsive-md table-sm">
      <thead class="dark">
        <tr>
          <th scope="col" class="text-center">Date</th>
          <th scope="col" class="text-center">Reference</th>
          <th scope="col" class="text-center">Amount</th>
          <th scope="col" class="text-center">Payment Method</th>
          <th scope="col" class="text-center">Actions</th>
        </tr>
      </thead>

      <tbody v-if="showLoader == 1">
        <tr class="shimmer-td">
          <td colspan="9">
            <content-placeholders class="shimmer" :rounded="true">
              <content-placeholders-text :lines="10" />
            </content-placeholders>
          </td>
        </tr>
      </tbody>

      <tbody v-if="displayedPayments.length > 0 && showLoader == 0">
        <tr v-for="(payments, index) in displayedPayments" :key="index">
          <td>
            <div class="text-center company-link">
              <span>{{ payments.date }}</span>
            </div>
          </td>
          <td>
            <div class="text-center company-link">
              <span>{{ payments.reference }}</span>
            </div>
          </td>
          <td>
            <div class="text-center company-size">
              <span>{{ payments.amount }}</span>
            </div>
          </td>
          <td>
            <div class="text-center company-location">
              <span>{{ payments.payment_method }}</span>
            </div>
          </td>
          <td>
            <PaymentEclipsReceivable :payment="payments" />
           
          </td>
        </tr>
      </tbody>
      <tbody v-if="displayedPayments.length == '0' && showLoader == 0">
        <tr class="no-data">
          <td colspan="9" class="shimmer">
            No Data Found.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import PaymentEclipsReceivable from "./paymentEclipsReceivable.vue";
export default {
  name: "ShowPaymentTableReceivable",
  props: {
    displayedPayments: Array,
    showLoader: Number,
  },
  data() {
    return {};
  },
  components: {
    PaymentEclipsReceivable
  },

  methods: {
    callModal: function(payments) {
      this.$root.$emit("openEditPayments", payments);
    },
getBack(){
  this.$router.go(-1)
},
    async deletePayments(id) {
      var formData = new FormData();
      formData.append("id", id);
      this.$swal({
        title: "Are you sure you want to <br /> delete Payment ?",
        text: "You can’t undo this action.",
        icon: "error",
        confirmButtonText: "Delete",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          return axios
            .post(
              process.env.VUE_APP_API_URL + "paymentShow/delete",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.$root.$emit("removePayment", id);
                this.$swal("Deleted!", "Payments has been deleted.", "success");
              } else {
                this.errorMessage = response.data.message;
              }
            });
        }
      });
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style></style>
