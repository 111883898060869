<template>
  <div class="text-center">
    <editPaymentReceivable :payment="payment" />
    <div class="actions-btn">
      <i class="fa fa-pencil-alt" @click="callModal(payment)"> </i>
      <i class="fa fa-trash" @click="deletePayments(payment.id,payment.sale_id)"> </i>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import editPaymentReceivable from "./editPaymentReceivable";
export default {
  name: "PaymentEclipsReceivable",
  props: {
    payment: Object,
  },
  data() {
    return {
      paymentMenu: false,
    };
  },
  components: {
    editPaymentReceivable,
  },
  methods: {
    callModal: function(payments) {
      this.$root.$emit("openEditPayments", payments);
    },

    async deletePayments(id,sale_id) {
      var formData = new FormData();
      formData.append("id", id);
      formData.append("sale_id", sale_id);
      this.$swal({
        title: "Are you sure you want to <br /> delete Payment ?",
        text: "You can’t undo this action.",
        icon: "error",
        confirmButtonText: "Delete",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          return axios
            .post(
              process.env.VUE_APP_API_URL + "paymentReceivable/deletePayment",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.$root.$emit("removepaymentReceivable", id);
                this.$swal("Deleted!", "Payments has been deleted.", "success");
              } else {
                this.errorMessage = response.data.message;
              }
            });
        }
      });
    },
  },
};
</script>

<style></style>
