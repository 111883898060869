<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <ShowPaymentTableReceivable :displayedPayments="payments" :showLoader="showLoader"/>
        <DatatableBottom :page="pager" :module="moduleName" :count="count" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import ShowPaymentTableReceivable from "../components/ShowPaymentReceivable/ShowPaymentTableReceivable.vue";
import DatatableBottom from "../components/tableview/DatatableBottom.vue";
import axios from "axios"

export default {
  name: "ShowPayments",
  components: {
    LeftMenu,
    Navbar,
    ShowPaymentTableReceivable,
    DatatableBottom
  },
    data: function() {
    return {
      moduleName: "",
      errorMessage: null,
      payments: [],
      showLoader: 0,
      page: 0,
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      count: 0,
      sale_id:''
    };
  },
  watch: {
    "$route.query.page": {
      immediate: false,
      handler(page) {
        page = parseInt(page) || 1;
        if (page !== this.pager.current_page) {
          this.showLoader = 1;
          this.fetchAllPaymentPayable(page);
        }
      },
    },
  },
   methods: {
    fetchAllPaymentPayable(page) {
       this.showLoader = 1;
      var formData = new FormData();
      console.log(this.sale_id)
      formData.append("sale_id", this.sale_id);
      axios
        .post(
          `${process.env.VUE_APP_API_URL}paymentReceivable/listingPayment?page=${page}`, formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.payments = response.data.data.data;
            console.log(this.payments)
            this.pager = response.data.data;
            this.count = response.data.data.total;
            this.moduleName =
              response.data.data.total > 0
                ? "Payment Payables"
                : "Payment Payable";
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
    getLinkUrl() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.sale_id = segment_array.pop();
      
      if (this.sale_id !== "show-payment-receivable") {
        this.fetchAllPaymentPayable(1);
      }else{
        this.$router.push({ name: "PaymentReceivable" });
      }
    },

  },

  mounted() {
    this.getLinkUrl();
    this.$root.$on("removepaymentReceivable", (id) => {
      const index = this.payments.findIndex((c) => c.id === id);
      this.payments.splice(index, 1);
      this.count = this.count - 1;
      this.pager.total = this.pager.total - 1;
    });
    this.$root.$on("reloadPayment", () => {
      this.fetchAllPaymentPayable(1);
    });
  },
};
</script>

<style></style>
