<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#editPaymentModal"
      ref="openModaleditPayment"
    ></button>
    <div
      class="modal fade"
      id="editPaymentModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">edit Payment</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModaleditPayment"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="registry-sign-up-form-content">
                <div class="company-info-wrapper">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-12 col-md-6 px-0 pr-md-2">
                        <div class="form-group">
                          <label for="customer_name">Reference</label>
                          <input
                            type="text"
                            v-model.trim="$v.details.reference.$model"
                            class="form-control"
                            placeholder="Reference"
                            :class="{
                              'is-invalid': $v.details.reference.$error,
                              'is-valid': !$v.details.reference.$invalid,
                            }"
                            autocomplete="off"
                            id="reference"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.reference.required">Reference is required</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 px-0">
                        <div class="form-group">
                          <label for="contact_person">
                            Date
                            <span class="astrick">*</span>
                          </label>
                          <input
                            v-model.trim="$v.details.date.$model"
                            id="date"
                            type="date"
                            class="form-control px-2"
                            placeholder="Date"
                            :class="{
                              'is-invalid': $v.details.date.$error,
                              'is-valid': !$v.details.date.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.date.required">Date is is required</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 px-0 pr-md-2">
                        <div class="form-group">
                          <label for="due_amount">
                            Due Amount
                            <span class="astrick">*</span>
                          </label>
                          <input
                            type="number"
                            v-model.trim="$v.details.due_amount.$model"
                            class="form-control"
                            id="due_amount"
                            placeholder="Due Amount"
                            readonly
                            :class="{
                              'is-invalid': $v.details.due_amount.$error,
                              'is-valid': !$v.details.due_amount.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.due_amount.required">Due Amount is required</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 px-0">
                        <div class="form-group">
                          <label for="amount">
                            Amount
                            <span class="astrick">*</span>
                          </label>
                          <input
                            type="number"
                            v-model.trim="$v.details.amount.$model"
                            class="form-control"
                            id="amount"

                            placeholder="Amount"
                            :class="{
                              'is-invalid': $v.details.amount.$error,
                              'is-valid': !$v.details.amount.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.amount.required">Amount is required</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group">
                          <label for="cash_method">
                            Cash Method
                            <span class="astrick">*</span>
                          </label>
                          <select
                            class="form-control pl-2"
                            name="cash_method"
                            id="cash_method"
                            required
                            v-model.trim="$v.details.payment_method.$model"
                            :class="{
                              'is-invalid': $v.details.payment_method.$error,
                              'is-valid': !$v.details.payment_method.$invalid,
                            }"
                          >
                          <option value="" selected >Cash Method</option>
                            <option value="Cash">Cash</option>
                            <option value="Credit Card">Credit Card</option>
                            <option value="Bank Transfer">Bank Transfer</option>
                            <option value="Cheque">Cheque</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="custom-btn2 py-2"
                @click="editPayment(0)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Edit Payment</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  name: "EditPaymentModal",
  props: {
    payment: Object
  },
  data: function() {
    return {
      details: {
        date: "",
        due_amount: "",
        amount: "",
        reference: "",
        payment_method: "",
        purchase_id: "",
        id: ''
      },
      isLoadingArray: []
    };
  },
  validations: {
    details: {
      date: { required },
      due_amount: { required },
      amount: { required },
      reference: { required },
      payment_method: { required }
    }
  },
  methods: {
    editPayment: function(index) {
      var formData = new FormData();
      formData.append("date", this.details.date);
      formData.append("due_amount", this.details.due_amount);
      formData.append("amount", this.details.amount);
      formData.append("reference", this.details.reference);
      formData.append("payment_method", this.details.payment_method);
      formData.append("purchase_id", this.details.purchase_id);
      formData.append("id", this.details.id);
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);

      axios
        .post(
          process.env.VUE_APP_API_URL + "paymentPurchase/updatePayment",
          formData
        )
        .then(response => {
          this.$set(this.isLoadingArray, index, false);
          if (response.data.statusCode === 200) {
            this.$swal("Updated!", "Payment has been Updated.", "success");
            this.details.date = "";
            this.details.amount = "";
            this.details.reference = "";
            this.details.payment_method = "";
            this.purchase_id = "";
            (this.isLoadingArray = []), this.$root.$emit("reloadPayment");
            var elem = this.$refs.closeModaleditPayment;
            if (elem) {
              elem.click();
            }
          } else {
            alert(response.data.message);
          }
        })
        .catch(error => {
          this.$set(this.isLoadingArray, index, false);
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    }
  },
  mounted() {
    this.$root.$on("openEditPayments", (payments) => {
        this.details.purchase_id = payments.purchase_id;
      this.details.due_amount = payments.purchase.due_amount;
      this.details.date = payments.date;
      this.details.amount= payments.amount;
      this.details.reference= payments.reference;
      this.details.payment_method= payments.payment_method;
      this.details.id= payments.id;
      var elem = this.$refs.openModaleditPayment;
      if (elem) {
        elem.click();
      }
    });
  }
};
</script>

<style></style>
